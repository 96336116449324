import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"

import Layout from "../../components/layout"
import Grid from "../../components/grid"
import SEO from "../../components/seo"
import Modal from "../../components/modal"
import ModalInner from "../../components/modalInner"

import styles from "../../styles/pages/team.module.scss"
import pageStyles from "../../styles/pages/page-styles.module.scss"

class TeamMember extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
  }

  modalToggle = () => {
    this.setState(state => ({
      modal: !state.modal,
    }))
  }

  render() {
    let {
      name,
      position,
      email,
      phoneNumber,
      experience,
      education,
      // headshot,
    } = this.props
    let aboutBlurb = this.props.aboutBlurb.aboutBlurb
    var rightSections = []
    if (experience) {
      rightSections.push({
        title: "Experience",
        items: experience,
      })
    }
    if (education) {
      rightSections.push({
        title: "Education",
        items: education,
      })
    }
    if (email || phoneNumber) {
      rightSections.push({
        title: "Contact",
        items: [email, phoneNumber],
      })
    }

    return (
      <>
        <button
          className={styles.memberCard}
          aria-label={name}
          onClick={this.modalToggle}
        >
          {/* <div className={styles.imageFill}>
            <Img
              fluid={headshot.fluid}
              className={styles.image}
              alt={headshot.description}
            />
          </div> */}
          <div className={styles.label}>
            <div className={styles.name}>{name}</div>
            <div className={styles.title}>{position}</div>
          </div>
        </button>
        <Modal
          className={styles.modal}
          display={this.state.modal}
          close={this.modalToggle}
        >
          <ModalInner
            header={
              <>
                <div className={styles.name}>{name}</div>
                <div className={styles.title}>{position}</div>
              </>
            }
            // footer={
            //   <div className={styles.contact}>
            //     {email && <span>{email}</span>}{" "}
            //     {email && phoneNumber && <>&nbsp;|&nbsp;</>}
            //     {phoneNumber && <span>{phoneNumber}</span>}
            //   </div>
            // }
            aboutBlurb={aboutBlurb}
            rightSections={rightSections}
          />
        </Modal>
      </>
    )
  }
}

const ExecutivePage = ({ data, location }) => {
  let members = data.contentfulOperatingExecutivePage.operatingExecutives.map(member => (
    <TeamMember {...member} key={member.id} />
  ))
  return (
    <Layout location={location.pathname}>
      <SEO title="Operating Executives" />
      <div className={pageStyles.pageContentContainer}>
        <h2 className={pageStyles.pageTitle}>Operating Executives</h2>
        <div className={styles.gridContainer}>
          <div className={styles.content}>
            <Grid maxColumns={3}>{members}</Grid>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default ExecutivePage

export const teamMemberQuery = graphql`
  query {
    contentfulOperatingExecutivePage {
      operatingExecutives {
        email
        experience
        education
        id
        name
        contentful_id
        position
        aboutBlurb {
          aboutBlurb
        }
        headshot {
          description
          fluid(maxWidth: 450, quality: 100) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`
